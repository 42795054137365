console.log(` 
Change this message, and make sure it changes in the browser 
to verify that you're working in the right files.`)

window.addEventListener('load', function() {
    document.querySelector('.loading').classList.add('hide')
});


var waypoint = new Waypoint({
    element: document.getElementById('fig1'),
    handler: function () {
        let btn =document.getElementById('fig1');
        btn.classList.add('active');
        console.log('hello world')

    },
    offset: '95%'
})
var waypoint = new Waypoint({
    element: document.getElementById('fig2'),
    handler: function () {
        let btn =document.getElementById('fig2');
        btn.classList.add('active');
        console.log('hello world')

    },
    offset: '95%'
})

var waypoint = new Waypoint({
    element: document.getElementById('btn1'),
    handler: function () {
        let btn =document.getElementById('btn1');
        btn.classList.add('active');
        console.log('hello world')

    },
    offset: '95%'
})
var waypoint = new Waypoint({
    element: document.getElementById('btn3'),
    handler: function () {
        let btn =document.getElementById('btn3');
        btn.classList.add('active');
        console.log('hello world')

    },
    offset: '95%'
})
var waypoint = new Waypoint({
    element: document.getElementById('btn5'),
    handler: function () {
        let btn =document.getElementById('btn5');
        btn.classList.add('active');
        console.log('hello world')

    },
    offset: '95%'
})
var waypoint = new Waypoint({
    element: document.getElementById('btn2'),
    handler: function () {
        let btn =document.getElementById('btn2');
        btn.classList.add('active');
        console.log('hello world')

    },
    offset: '95%'
})
var waypoint = new Waypoint({
    element: document.getElementById('btn4'),
    handler: function () {
        let btn =document.getElementById('btn4');
        btn.classList.add('active');
        console.log('hello world')

    },
    offset: '95%'
})
var waypoint = new Waypoint({
    element: document.getElementById('btn6'),
    handler: function () {
        let btn =document.getElementById('btn6');
        btn.classList.add('active');
        console.log('hello world')

    },
    offset: '95%'
})
var waypoint = new Waypoint({
    element: document.getElementById('img1'),
    handler: function () {
        let btn =document.getElementById('img1');
        btn.classList.add('active');
        console.log('hello world')

    },
    offset: '95%'
})
var waypoint = new Waypoint({
    element: document.getElementById('img3'),
    handler: function () {
        let btn =document.getElementById('img3');
        btn.classList.add('active');
        console.log('hello world')

    },
    offset: '95%'
})
var waypoint = new Waypoint({
    element: document.getElementById('img5'),
    handler: function () {
        let btn =document.getElementById('img5');
        btn.classList.add('active');
        console.log('hello world')

    },
    offset: '95%'
})
var waypoint = new Waypoint({
    element: document.getElementById('img2'),
    handler: function () {
        let btn =document.getElementById('img2');
        btn.classList.add('active');
        console.log('hello world')

    },
    offset: '95%'
})
var waypoint = new Waypoint({
    element: document.getElementById('img4'),
    handler: function () {
        let btn =document.getElementById('img4');
        btn.classList.add('active');
        console.log('hello world')

    },
    offset: '95%'
})
var waypoint = new Waypoint({
    element: document.getElementById('img6'),
    handler: function () {
        let btn =document.getElementById('img6');
        btn.classList.add('active');
        console.log('hello world')

    },
    offset: '95%'
})
// var waypoint = new Waypoint({
//     element: document.getElementById('teamMember1'),
//     handler: function () {
//         let btn =document.getElementById('teamMember1');
//         btn.classList.add('active');
//         console.log('hello world')

//     },
//     offset: '95%'
// })
// var waypoint = new Waypoint({
//     element: document.getElementById('teamMember2'),
//     handler: function () {
//         let btn =document.getElementById('teamMember2');
//         btn.classList.add('active');
//         console.log('hello world')

//     },
//     offset: '95%'
// })
// var waypoint = new Waypoint({
//     element: document.getElementById('teamMember3'),
//     handler: function () {
//         let btn =document.getElementById('teamMember3');
//         btn.classList.add('active');
//         console.log('hello world')

//     },
//     offset: '95%'
// })


// var waypoint = new Waypoint({
//     element: document.getElementById('btn-left'),
//     handler: function () {
//         let btn =document.getElementById('btn-left');
//         btn.classList.add('active');
//         console.log('hello world')

//     },
//     offset: '95%'
// })